<script>
import { cloneDeep } from "lodash";
import CalendarApiService from "@/services/calendarService";
import UserConfigMixin from "@/mixins/userConfigMixin";

export default {
  props: ["value", "dashboardId"],
  components: {},
  mixins: [UserConfigMixin],
  data() {
    return {
      callBack: null,
      isLoading: false,
      capacityChartType: "Progress",
      progress: [],
      installers: [],
      selectedProgress: [],
      selectedInstallers: [],
      openProgressDropDown: false,
      openInstallersDropDown: false,
    };
  },
  created() {
    this.loadProgress();
    this.loadInstallers();
  },
  beforeDestroy() {},
  computed: {
    openModal: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    sortedVisibleProgress() {
      let visibleProgress = [];
      for (var progress of this.progress) {
        if (this.userCanSeeProgress(progress)) {
          let copy = cloneDeep(progress);
          visibleProgress.push(copy);
        }
      }

      return visibleProgress.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
    },
  },
  methods: {
    async create() {
      this.loadProgress();
      this.loadInstallers();
    },
    cancel() {
      //close modal
      this.$emit("input", false);
      this.$emit("cancel");
    },
    async add() {
      let model = {
        capacityDashboardId: this.dashboardId,
        type: this.capacityChartType,
        selectedProgressId: this.selectedProgress.map((x) => {
          return x.id;
        }),
        selectedInstallersIdPublic: this.selectedInstallers.map((x) => {
          return x.idPublic;
        }),
      };
      await CalendarApiService.createCapacityChart(model);

      this.$emit("capacity-chart-created");
      this.openModal = false;

      this.resetFields();
    },
    resetFields() {
      this.capacityChartType = "Progress";
      this.selectedInstallers = [];
      this.selectedProgress = [];
    },
    loadInstallers() {
      return CalendarApiService.getInstallers().then((response) => {
        this.installers = response.data.data;
      });
    },
    loadProgress() {
      return CalendarApiService.getAllProgressListView().then((response) => {
        this.progress = response.data.data;
      });
    },
  },
};
</script>

<template>
  <b-modal
    v-model="openModal"
    @close="cancel"
    :title="$t('general.addCapacityChart')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    size="md"
    id="moveEventsModal"
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-12">
        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          color="#f1b44c"
          :width="70"
          :height="70"
          loader="dots"
          :is-full-page="false"
        >
        </loading>
        <label>{{ $t("general.chooseType") }}</label>
        <b-form-radio-group class="mb-3" v-model="capacityChartType">
          <b-form-radio value="Progress" class="mb-1">{{
            $t("general.progress")
          }}</b-form-radio>
          <b-form-radio value="Installation" class="mb-1">{{
            $t("general.installation")
          }}</b-form-radio>
        </b-form-radio-group>

        <div
          :style="{ height: openProgressDropDown == true ? '400px' : 'auto' }"
          v-if="capacityChartType == 'Progress'"
        >
          <label>{{ $t("general.selectProgress") }}</label>
          <multiselect
            v-model="selectedProgress"
            :options="sortedVisibleProgress"
            @open="openProgressDropDown = true"
            @close="openProgressDropDown = false"
            :show-labels="false"
            label="name"
            track-by="id"
            multiple
            :placeholder="''"
          >
          </multiselect>
        </div>

        <div
          :style="{ height: openInstallersDropDown == true ? '400px' : 'auto' }"
          v-if="capacityChartType == 'Installation'"
        >
          <label>{{ $t("general.selectInstallers") }}</label>
          <multiselect
            v-model="selectedInstallers"
            :options="installers"
            @open="openInstallersDropDown = true"
            @close="openInstallersDropDown = false"
            :show-labels="false"
            label="name"
            track-by="idPublic"
            multiple
            :placeholder="''"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <template #modal-footer class="text-right pt-3">
      <b-button @click="cancel()" class="ml-1">{{
        $t("general.cancel")
      }}</b-button>
      <b-button @click="add()" class="ml-1" variant="primary">{{
        $t("general.add")
      }}</b-button>
    </template>
  </b-modal>
</template>