<script>
import { required } from "vuelidate/lib/validators";
import CalendarApiService from "@/services/calendarService";

export default {
  props: ['value'],
  components: {
  },
  data() {
    return {
      callBack: null,
      isLoading: false,
      dashboardForm: {
        name: null
      },
      dashboardSubmit: false,
    };
  },
  created(){

  },
  beforeDestroy() {

  },   
  computed: {
    openModal: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    async create(){
      //create new dashboard
      this.dashboardSubmit = true;
      this.$v.dashboardForm.$touch()

      if (!this.$v.dashboardForm.$invalid) {
        this.dashboardSubmit = false;

        this.isLoading = true;
        //Create dashboard
        await CalendarApiService.createCapacityDashboard(this.dashboardForm.name)
        this.isLoading = false;

        //close modal
        this.$emit('input', false)
        this.$emit('created-capacity-dashboard')

        //reset form
        this.dashboardForm.name = null;
      }


    },
    cancel(){

      //close modal
      this.$emit('input', false)
      this.$emit('cancel')
    }
  },
  validations: {
    dashboardForm: {
      name: { required }
    }
  },   
};
</script>

<template>
  <b-modal
    v-model="openModal"
    @close="cancel"
    :title="$t('general.createCapacityDashboard')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    size="md"
    id="moveEventsModal"
    no-close-on-backdrop
  > 
    <div class="row">
      <div class="col-12">
        <loading :active.sync="isLoading" 
          :can-cancel="false"
          color='#f1b44c'
          :width=70
          :height=70
          loader='dots'
          :is-full-page="false"> </loading>          
        <b-form-group class="mb-0" style="position:relative;">
          <label>{{$t('general.name')}}</label>
          <b-form-input 
            type="text"
            :class="{ 'is-invalid': dashboardSubmit && $v.dashboardForm.name.$error }"
            v-model="dashboardForm.name" 
            :placeholder="$t('general.name')" ></b-form-input>
          <div v-if="dashboardSubmit && $v.dashboardForm.name.$error" class="invalid-feedback">
            <span v-if="!$v.dashboardForm.name.required">{{$t('general.requiredValue')}}</span>
          </div> 
        </b-form-group>  
      </div>     
    </div>
    <template #modal-footer class="text-right pt-3">
      <b-button @click="cancel()" class="ml-1">{{$t('general.cancel')}}</b-button>
      <b-button @click="create()" class="ml-1" variant="primary">{{$t('general.create')}}</b-button>
    </template>
  </b-modal>
</template>