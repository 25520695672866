<script>
import CalendarApiService from "@/services/calendarService";
import CapacitiesChart from "@/router/views/dashboards/capacities/components/capacitiesChart";
import AddCapacityChartModal from "@/router/views/dashboards/capacities/components/modals/addCapacityChartModal";
import EventBus from "@/router/views/dashboards/capacities/eventBus/eventBus";
import draggable from "vuedraggable";
import { cloneDeep } from "lodash";
/**
 * Dashboard Component
 */
export default {
  props: ["sideBarCollapsed", "sideBarData"],
  page: {},
  components: {
    CapacitiesChart,
    draggable,
    AddCapacityChartModal,
  },
  data() {
    return {
      values: [],
      capacitiesCharts: [],
      dashboard: null,
      addCapacityChartModalOpen: false,
    };
  },
  created() {
    EventBus.$on("selected-dashboard", this.selectedDashboard);
    EventBus.$on("chart-added-to-dashboard", this.loadCharts);
  },
  beforeDestroy() {
    EventBus.$off("selected-dashboard", this.selectedDashboard);
    EventBus.$off("chart-added-to-dashboard", this.loadCharts);
  },
  mounted() {
    //set values to select
    for (var value of this.measureTypes) {
      let model = {
        name: value,
        checked: false,
      };

      this.values.push(model);
    }
  },
  methods: {
    async selectedDashboard(dashboard) {
      this.dashboard = cloneDeep(dashboard);

      //load dashboard charts
      await this.loadCharts();
    },
    async loadCharts() {
      if (this.dashboard == null) {
        this.capacitiesCharts = [];
        return;
      }

      this.capacitiesCharts = [];
      //load dashboard charts
      let response = await CalendarApiService.getCapacityCharts(
        this.dashboard.id
      );

      // let charts = response.data.data;
      let orderedCharts = response.data.data.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
      for (let chart of orderedCharts) {
        let capacitiesChartModel = {
          capacityDashboardId: chart.capacityDashboardId,
          id: chart.id,
          uiGuid: Math.floor(Math.random() * Date.now()),
          type: chart.type,
          item: chart.type == "Progress" ? chart.progress : chart.installers,
          valueTypesSelected: chart.valueTypesSelected,
          stepIdPublic: chart.type == "Progress" ? chart.progress[0].stepIdPublic : null
        };

        this.capacitiesCharts.push(capacitiesChartModel);
      }
    },
    addChart() {
      //open chart modal
      this.addCapacityChartModalOpen = true;
    },
    openSideBar() {
      this.$emit("open-sidebar");
    },
    setSelectedItem(capacitiesChartModel) {
      this.capacitiesCharts.push(capacitiesChartModel);
    },
    updateDisplayOrder(ev) {
      if (ev.moved) {
        //save order
        let order = 1;
        let data = this.capacitiesCharts.map((x) => {
          let model = {
            id: x.id,
            order: order,
          };
          order++;
          return model;
        });
        CalendarApiService.updateCapacityChartsOrder(data);
      }
    },
    reloadCapacityCharts() {
      //reload charts
      this.loadCharts();
    },
    removeCapacityChart(index) {
      this.capacitiesCharts.splice(index, 1);
    },
  },
  computed: {},
};
</script>

<template>
  <div
    class="p-0 capacitiesDashboardChartContent"
    style="
      position: relative;
      display: flex;
      min-width: 0;
      flex-direction: column;
      flex: 1 1 0;
    "
  >
    <!-- <label v-if="sideBarCollapsed" @click="openSideBar()" style="z-index:999; left:25px;top:4px; position:absolute; zfont-size:1.5em; color:#cacaca; cursor:pointer;" zclass="fas fa-chevron-right">{{$t('general.openMenu')}}</label>        -->
    <i
      v-if="sideBarCollapsed"
      @click="openSideBar()"
      style="
        z-index: 999;
        left: 6px;
        top: 10px;
        position: absolute;
        font-size: 1.5em;
        color: #cacaca;
        cursor: pointer;
      "
      class="fas fa-chevron-right"
    ></i>
    <div
      class="p-4"
      style="
        position: relative;
        overflow: auto;
        scrollbar-width: thin;
        flex: 1 1 0;
        min-width: 0;
        display: flex;
        flex-direction: column;
      "
      id="capacitiesChartContainer"
    >
      <draggable
        v-model="capacitiesCharts"
        @change="updateDisplayOrder($event)"
        class="draggableContainer"
        style="display: flex; flex: 1 1 0; min-width: 0; flex-direction: column"
      >
        <CapacitiesChart
          v-for="(item, index) in capacitiesCharts"
          :key="item.uiGuid"
          :model="capacitiesCharts[index]"
          @capacity-chart-removed="removeCapacityChart(index)"
          :filters="sideBarData.filters"
        >
        </CapacitiesChart>
      </draggable>
    </div>

    <AddCapacityChartModal
      v-if="dashboard != null"
      v-model="addCapacityChartModalOpen"
      :dashboardId="dashboard.id"
      @capacity-chart-created="reloadCapacityCharts"
    ></AddCapacityChartModal>
  </div>
</template>

<style scoped>
.capacitiesDashboardChartContent >>> .simplebar-content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}
</style>
