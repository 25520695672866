
<script>



export default {
  props: ['value'],
  components: {

  },
  data() {
    return {
      callBack: null,
    };
  },
  created(){

  },
  beforeDestroy() {

  },   
  computed: {
    openModal: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    move(answer){
      this.$emit('input', false)
      this.$emit('on-answer', answer)
    },
    cancel(){
      this.$emit('input', false)
      this.$emit('on-cancel')
    }
  },
};
</script>

<template>
  <b-modal
    v-model="openModal"
    @close="cancel"
    :title="$t('general.moveNextProgressEventsTitle')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    id="moveEventsModal"
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-12">
        <p>{{$t('general.moveNextProgressEventsModalContent')}}</p> 
      </div>     
    </div>
    <template #modal-footer class="text-right pt-3">
      <b-button @click="cancel()" class="ml-1">{{$t('general.cancel')}}</b-button>
      <b-button @click="move(false)" class="ml-1" variant="primary">{{$t('general.no')}}</b-button>
      <b-button @click="move(true)" class="ml-1" variant="primary">{{$t('general.yes')}}</b-button>
    </template>
  </b-modal>
</template>