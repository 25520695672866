<!-- eslint-disable vue/no-unused-components -->
<script>
import CapacitiesSideBar from "@/router/views/dashboards/capacities/components/capacitiesSidebar";
import CapacitiesChartContent from "@/router/views/dashboards/capacities/components/capacitiesChartContent";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "capacitiesDashboard",
  },
  components: {
    CapacitiesSideBar,
    CapacitiesChartContent,
  },
  data() {
    return {
      sideBarCollapsed: false,
      sideBarData: {
        filters: {
          workOrderTypes: [],
          intervalType: 'days',
          dateRange: [this.$dayjs().toDate(),  this.$dayjs().add(1, 'month').toDate()]
        }     
      }
    };
  },
  created (){

  },
  mounted() {

  },
  methods: {
    closeSidebar(){
      this.sideBarCollapsed = true;
    },
    openSidebar(){
      this.sideBarCollapsed = false;
    }, 
  }, 
};
</script>

<template>
  <Layout :contentPadding="false">
    <div class="capacitiesDashboardLayout chromeThinScrollBar" style="display:flex; min-height:0; flex:1 1 0; position:relative;" :class="{ capacitiesSideBarCollapsed: !sideBarCollapsed}">
      <CapacitiesSideBar 
        v-model="sideBarData"
        :sideBarCollapsed="sideBarCollapsed"
        @close-sidebar="closeSidebar" 
      />

      <CapacitiesChartContent
        ref="capacitiesChartContent"
        :sideBarData="sideBarData"
        :sideBarCollapsed="sideBarCollapsed" 
        @open-sidebar="openSidebar"
      />
    </div>
  </Layout>
</template>
