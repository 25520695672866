<script>
import CalendarApiService from "@/services/calendarService";
import LineChart from "../charts/LineChart";
import { cloneDeep, isEqual } from "lodash";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import MoveProgressEventsModal from "@/router/views/dashboards/capacities/components/modals/moveProgressEventsModal";
import EventBus from "@/router/views/dashboards/capacities/eventBus/eventBus";
import UserConfigMixin from "@/mixins/userConfigMixin";
import CapacityService from "@/services/capacity-service";

/**
 * Dashboard Component
 */
export default {
  props: ["model", "filters"],
  page: {},
  components: {
    LineChart,
    DatePicker,
    MoveProgressEventsModal,
  },
  mixins: [UserConfigMixin],
  data() {
    return {
      isLoading: false,
      isUpdatingValueTypePreference: false,
      openMoveProgressEventsModal: false,
      valueTypes: [],
      valuesForDates: [],
      valuesForDatesCopy: [],
      openEditCapacities: false,
      hideCompleted: false,
      selectedValueType: null,
      selectedValueForDateIndex: null,
      editSectionItems: [],
      blockerEvents: [],
      valueTypesColor: [
        {
          type: "Products",
          color: "#8170ff", //'#580aff'//'#8170ff'
        },
        {
          type: "Parts",
          color: "#6aebb3", //'#147df5'//'#6aebb3'
        },
        {
          type: "SheetsQuantity",
          color: "#ff7091", //'#0aefff'//'#ff7091'
        },
        {
          type: "NumericValue",
          color: "#ffc670", //'#be0aff'//'#ffc670'
        },
        {
          type: "SquareFoot",
          color: "#ff7070", //'#ff8700'//'#ff7070'
        },
        {
          type: "SquareMeter",
          color: "#cbff70", //'#deff0a'//'#cbff70'
        },
        {
          type: "TimePerWorkOrder",
          color: "#70ecff", //'#a1ff0a'//'#70ecff'
        },
        {
          type: "DoorsQuantity",
          color: "#d970ff", //'#0aff99'//'#d970ff'
        },
      ],
      capacities: [],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        onClick: async (evt) => {
          let chartElem = this.$refs.lineChart.$data._chart;

          const points = chartElem.getElementsAtEventForMode(
            evt,
            "nearest",
            { intersect: true },
            true
          );

          let data = chartElem.data;

          if (points.length) {
            const firstPoint = points[0];
            let datasetIndex = firstPoint._datasetIndex;
            let index = firstPoint._index;

            //open edit section
            this.openEditCapacities = true;
            //copy pour le preview dans section edit(pour pouvoir cancel et remettre letat)
            this.valuesForDatesCopy = cloneDeep(this.valuesForDates);

            let valueType = data.datasets[datasetIndex].valueType;
            this.selectedValueType = valueType;
            this.selectedValueForDateIndex = index;

            if (this.valuesForDates[index]) {
              let ids = [];
              let response;

              if (this.model.type == "Progress") {
                ids = this.valuesForDates[index].progressAppointmentsId;
                response =
                  await CalendarApiService.getProgressAppointmentsWithValues(
                    ids
                  );

                if (response != null) {
                  this.setProgressAppointments(response.data.data);
                }
              } else if (this.model.type == "Installation") {
                ids = this.valuesForDates[index].workOrdersIdPublic;
                response =
                  await CalendarApiService.getWorkOrdersNoWorkOrderProgress(
                    ids
                  );

                if (response != null) {
                  this.setWorkOrders(response.data.data);
                }
              }
            }
          } else {
            this.openEditCapacities = false;
          }
        },
      },
    };
  },
  async created() {
    EventBus.$on("reload-all-progress-charts", this.loadProgressValues);
    EventBus.$on("reload-all-installer-charts", this.loadInstallerValues);

    this.$bus.$on("locale-updated", this.localeUpdated);

    this.isLoading = true;

    await this.loadCapacitiesAndSelectedValueTypes();

    await this.loadBlockerEvents();

    await this.loadValues();

    this.isLoading = false;

    //Set watch sur filters ici pour etre certain que les capacities et values type sont load avant de get les values
    //probleme de timing si watch est declare sur lobjet vue. de temps en temps load values serait call avant que created ait fini de load les capacites et value types
    this.$watch("filters", (oldValue, newValue) => {
      //verification de la valeur car cest appele meme si filter nest pas diffrent a cause du deepClone des filters du parent
      if (!isEqual(oldValue, newValue)) {
        this.loadValues();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("locale-updated", this.localeUpdated);
    EventBus.$off("reload-all-progress-charts", this.loadProgressValues);
    EventBus.$off("reload-all-installer-charts", this.loadInstallerValues);
  },
  mounted() {},
  methods: {
    setProgressAppointments(progressAppointments) {
      let items = progressAppointments;
      items.forEach((x) => {
        let values = x.values;
        let model = {
          workOrderName: x.workOrder.name,
          progressAppointmentId: x.id,
          valueForType: this.getValueForType(this.selectedValueType, values),
          values: values,
          daySpan: Math.abs(this.$dayjs(x.startDate).diff(x.endDate, "day")),
          startDate: this.$dayjs(x.startDate).toDate(),
          oldStartDate: this.$dayjs(x.startDate).toDate(),
          endDate: this.$dayjs(x.endDate).toDate(),
          oldEndDate: this.$dayjs(x.endDate).toDate(),
          completed: x.workOrderStepPercentCompleted == 1 ? true : false,
        };

        //ajouter a la liste si le progress appointment nest pas deja la
        if (
          this.editSectionItems.find((item) => {
            return item.progressAppointmentId == x.id;
          }) == undefined
        ) {
          this.editSectionItems.push(model);
        }
      });
    },
    setWorkOrders(workOrders) {
      workOrders.forEach((x) => {
        let values = x.workOrderValues;
        let model = {
          workOrder: x,
          workOrderName: x.name,
          workOrderIdPublic: x.idPublic,
          valueForType: this.getValueForType(this.selectedValueType, values),
          values: values,
          daySpan: Math.abs(
            this.$dayjs(x.installationDate).diff(x.installationEndDate, "day")
          ),
          startDate: this.$dayjs(x.installationDate).toDate(),
          oldStartDate: this.$dayjs(x.installationDate).toDate(),
          endDate: this.$dayjs(x.installationEndDate).toDate(),
          oldEndDate: this.$dayjs(x.installationEndDate).toDate(),
        };

        //ajouter a la liste si le work order nest pas deja la
        if (
          this.editSectionItems.find((item) => {
            return item.workOrderIdPublic == x.idPublic;
          }) == undefined
        ) {
          this.editSectionItems.push(model);
        }
      });
    },
    localeUpdated() {
      this.$forceUpdate();
    },
    getValueForType: function (type, values) {
      let value = 0;

      let elem = values.filter((x) => {
        return x.measureType == type;
      });

      if (elem.length > 0) {
        elem = elem[0];
        value = elem.value;
      }

      return value;
    },
    getDateLabels: function () {
      let labels = [];

      //Utiliser valuesForDate pour populer les labels
      for (let valuesForDate of this.valuesForDates) {
        let date = valuesForDate.date;
        labels.push(this.$dayjs(date).format("L"));
      }

      return labels;
    },
    getValueTypeData: function (valueType) {
      let data = [];

      for (let valuesForDate of this.valuesForDates) {
        let valueForType = valuesForDate.values.filter((x) => {
          return x.type == valueType;
        });
        if (valueForType.length > 0) {
          valueForType = valueForType[0];
          let totalValue = valueForType.totalValue;
          let nonCompleted = valueForType.nonCompleted;
          data.push(this.hideCompleted ? nonCompleted : totalValue);
        }
      }

      return data;
    },
    getValueTypeColor(type) {
      let color = "blue";

      let colorAssoc = this.valueTypesColor.filter((x) => {
        return x.type == type;
      });
      if (colorAssoc.length > 0) {
        colorAssoc = colorAssoc[0];
        color = colorAssoc.color;
      }

      return color;
    },
    getDataSet() {
      let dataSet = [];

      //for each value type checked
      let checkedValueTypes = this.valueTypes.filter((x) => {
        return x.selected == true;
      });

      //=====VALUES(full line)=====
      //work order steps total values
      for (let value of checkedValueTypes) {
        let valueType = value.valueType;
        let data = this.getValueTypeData(valueType);
        let color = this.getValueTypeColor(valueType);
        let model = {
          data: data,
          label: this.$t("general." + valueType),
          valueType: valueType,
          borderColor: color,
          fill: false,
        };

        dataSet.push(model);
      }

      //=====CAPACITIES(dashed line)=====
      //capacities for selected item
      for (let value of checkedValueTypes) {
        let valueType = value.valueType;
        let dates = this.valuesForDates.map((x) => new Date(x.date));
        let data = CapacityService.getMaximumCapacitiesByPeriod(
          this.filters.intervalType,
          this.capacities.filter((x) => {
            return x.measureType == valueType;
          }),
          this.model.item,
          dates,
          this.filters.dateRange[1],
          this.blockerEvents
        );
        let color = this.getValueTypeColor(valueType);
        let model = {
          data: data,
          label:
            this.$t("general." + valueType) + " " + this.$t("general.capacity"),
          valueType: "Capacity",
          borderColor: color,
          fill: false,
          borderDash: [7, 4],
          borderWidth: 2,
          radius: 2,
        };

        dataSet.push(model);
      }

      return dataSet;
    },
    loadCapacitiesAndSelectedValueTypes: async function () {
      let response = await CalendarApiService.getValueTypesSelected(
        this.model.id
      );
      let valueTypesSelected = response.data.data;

      if (this.model.type == "Progress") {
        let response = await CalendarApiService.getProgressCapacities(
          this.model.item.map((x) => {
            return x.stepIdPublic;
          })
        );
        this.capacities = response.data.data;
      } else if (this.model.type == "Installation") {
        let response = await CalendarApiService.getInstallersCapacities(
          this.model.item.map((x) => {
            return x.idPublic;
          })
        );
        this.capacities = response.data.data;
      }

      //set value types for progress capacities
      for (let capacity of this.capacities) {
        //verif si deja dans la liste
        let isInList = this.valueTypes.findIndex((x) => {
          return x.valueType == capacity.measureType;
        });
        if (isInList != -1) {
          continue;
        }

        //verif data du chart pour savoir si une preference a ete sauvegarde dans la BD
        let valueTypeIndex = valueTypesSelected.findIndex((x) => {
          return x.valueType == capacity.measureType;
        });

        let capacityChartId = this.model.id;
        let valueType = capacity.measureType;
        let selected = true;
        let id = null;

        if (valueTypeIndex != -1) {
          let valueType = valueTypesSelected[valueTypeIndex];
          selected = valueType.selected;
          id = valueType.id;
        }

        let model = {
          id: id,
          valueType: valueType,
          selected: selected,
          capacityChartId: capacityChartId,
        };

        this.valueTypes.push(model);
      }
    },
    getWorkOrderProgressValuesForDateRangeModel(valueType = null) {
      return {
        startDate: this.filters.dateRange[0],
        endDate: this.filters.dateRange[1],
        intervalType: this.filters.intervalType,
        workOrderTypesIdPublic: !this.filters.workOrderTypes
          ? null
          : this.filters.workOrderTypes.map((x) => {
              return x.idPublic;
            }),
        progressId: this.model.item.map((x) => {
          return x.id;
        }),
        measureTypes:
          valueType == null
            ? this.valueTypes
                .filter((x) => {
                  return x.selected == true;
                })
                .map((x) => {
                  return x.valueType;
                })
            : [valueType],
      };
    },
    getWorkOrderValuesForDateRangeModel(valueType = null) {
      return {
        startDate: this.filters.dateRange[0],
        endDate: this.filters.dateRange[1],
        intervalType: this.filters.intervalType,
        workOrderTypesIdPublic: !this.filters.workOrderTypes
          ? null
          : this.filters.workOrderTypes.map((x) => {
              return x.idPublic;
            }),
        installersIdPublic: this.model.item.map((x) => {
          return x.idPublic;
        }),
        measureTypes:
          valueType == null
            ? this.valueTypes
                .filter((x) => {
                  return x.selected == true;
                })
                .map((x) => {
                  return x.valueType;
                })
            : [valueType],
      };
    },
    loadValues: async function () {
      if (this.model.type == "Progress") {
        this.loadProgressValues();
      } else if (this.model.type == "Installation") {
        this.loadInstallerValues();
      }
    },
    loadBlockerEvents: async function () {
      let resp = await CalendarApiService.getBlockerEvents(
        this.$dayjs(this.filters.dateRange[0]).format("YYYY-MM-DD"),
        this.$dayjs(this.filters.dateRange[1]).format("YYYY-MM-DD"),
        0,
        this.model.type == "Progress" ? [this.model.item.map((x) => x.stepIdPublic)] : [],
        this.model.type == "Installation"
          ? this.model.item.map((x) => x.idPublic)
          : [],
        []
      );
      this.blockerEvents = resp.data;
    },
    loadProgressValues: async function () {
      if (this.model.type != "Progress") return;

      let model = this.getWorkOrderProgressValuesForDateRangeModel();
      this.isLoading = true;
      // eslint-disable-next-line no-unused-vars
      let resp =
        await CalendarApiService.getWorkOrderProgressValuesForDateRange(model);
      this.isLoading = false;
      this.valuesForDates = resp.data.data;
    },
    loadInstallerValues: async function () {
      if (this.model.type != "Installation") return;

      let model = this.getWorkOrderValuesForDateRangeModel();
      this.isLoading = true;
      // eslint-disable-next-line no-unused-vars
      let resp =
        await CalendarApiService.getWorkOrderInstallationValuesForDateRange(
          model
        );
      this.isLoading = false;
      this.valuesForDates = resp.data.data;
    },
    loadValuesForValueType: async function (valueType) {
      let data;

      this.isLoading = true;
      if (this.model.type == "Progress") {
        let model = this.getWorkOrderProgressValuesForDateRangeModel(valueType);
        let resp =
          await CalendarApiService.getWorkOrderProgressValuesForDateRange(
            model
          );
        data = resp.data.data;
      } else if (this.model.type == "Installation") {
        let model = this.getWorkOrderValuesForDateRangeModel();
        let resp =
          await CalendarApiService.getWorkOrderInstallationValuesForDateRange(
            model
          );
        data = resp.data.data;
      }
      this.isLoading = false;

      if (data != null) {
        for (let valuesForDate of this.valuesForDates) {
          let sameDateValues = data.filter((x) => {
            return x.date == valuesForDate.date;
          });
          if (sameDateValues.length > 0) {
            sameDateValues = sameDateValues[0];
            let sameDateValuesForType = sameDateValues.values.filter((x) => {
              return x.type == valueType;
            });

            let valuesForType = valuesForDate.values.filter((x) => {
              return x.type == valueType;
            });
            if (valuesForType.length > 0) {
              valuesForType = valuesForType[0];
              if (sameDateValuesForType.length > 0) {
                sameDateValuesForType = sameDateValuesForType[0];
                valuesForType.totalValue = sameDateValuesForType.totalValue;
              }
            } else {
              let totalValue = 0;
              if (sameDateValuesForType.length > 0) {
                sameDateValuesForType = sameDateValuesForType[0];
                totalValue = sameDateValuesForType.totalValue;
              }
              let model = {
                type: valueType,
                totalValue: totalValue,
              };

              valuesForDate.values.push(model);
            }
          }
        }
      }
    },
    async toggleValueType(value) {
      if (value.selected == true) {
        //load values
        await this.loadValuesForValueType(value.valueType);
      }

      //save backend
      let model = {
        id: value.id,
        capacityChartId: value.capacityChartId,
        valueType: value.valueType,
        selected: value.selected,
      };

      if (value.id == null) {
        //permet dempecher de recliquer sur la switch avant la creation de la preference en backend
        //loading overlay
        this.isUpdatingValueTypePreference = true;
      }

      let response = await CalendarApiService.setChartValueTypeSelected(model);
      let id = response.data.data;
      value.id = id;
      this.isUpdatingValueTypePreference = false;
    },
    changeStartDate(item) {
      //faire suivre le end date
      let oldEndDate = this.$dayjs(item.oldEndDate);
      let oldStartDate = this.$dayjs(item.oldStartDate);

      let diff = oldEndDate.diff(oldStartDate, "day");

      item.endDate = this.$dayjs(item.startDate).add(diff, "day").toDate();

      this.changeDate(item);
    },
    changeEndDate(item) {
      this.changeDate(item);
    },
    updateIntervalValuesForDateRange(
      appointmentData,
      startDate,
      endDate,
      operation
    ) {
      let dateType =
        this.filters.intervalType == "days"
          ? "day"
          : this.filters.intervalType == "weeks"
          ? "week"
          : "month";
      let i = 0;
      let appointmentTimeSpan = this.$dayjs(endDate).diff(startDate, "day");
      while (i < appointmentTimeSpan) {
        let currentDateWithoutTime = this.$dayjs(startDate).add(i, "day");
        let valuesForDate = this.valuesForDates.filter((x) => {
          return (
            this.$dayjs(currentDateWithoutTime).isBetween(
              this.$dayjs(x.date),
              this.$dayjs(x.date).add(1, dateType)
            ) || this.$dayjs(currentDateWithoutTime).isSame(this.$dayjs(x.date))
          );
        });
        if (valuesForDate.length > 0) {
          valuesForDate = valuesForDate[0];
        } else {
          return;
        }

        for (let valueData of valuesForDate.values) {
          let type = valueData.type;
          let totalValueForType = valueData.totalValue;

          let values = appointmentData.values;
          let valueForType = this.getValueForType(type, values);

          let valueToAdd = 0;
          if (this.model.type == "Progress") {
            valueToAdd = valueForType / appointmentTimeSpan;
          } else if (this.model.type == "Installation") {
            //Si installation, ajouter seulement la partie qui revietn a linstaller(ex.: si work order a 60 produits, et 2 installers, chaque installer aura 30 produits.)
            //multiplier par le nombre ditem dans la chart
            valueToAdd = this.getValueToAddForInstallation(
              appointmentData.workOrder.installers,
              appointmentTimeSpan,
              valueForType
            );
          }

          valueData.totalValue =
            operation == "+"
              ? totalValueForType + valueToAdd
              : operation == "-"
              ? totalValueForType - valueToAdd
              : 0;
        }

        i++;
      }
    },
    getValueToAddForInstallation(
      installers,
      appointmentTimeSpan,
      valueForType
    ) {
      var valueToAdd = 0;

      var nbOfMatchingInstallerInChart = 0;
      var workOrderInstallersIdPublic = installers.map((x) => {
        return x.idPublic;
      });
      var selectedInstallersIdPublic = this.model.item.map((x) => {
        return x.idPublic;
      });
      nbOfMatchingInstallerInChart = selectedInstallersIdPublic.filter((x) => {
        return workOrderInstallersIdPublic.includes(x);
      }).length;
      valueToAdd =
        (valueForType / installers.length / appointmentTimeSpan) *
        nbOfMatchingInstallerInChart;

      return valueToAdd;
    },
    changeDate(appointmentData) {
      //enlever les values pour le old interval
      this.updateIntervalValuesForDateRange(
        appointmentData,
        appointmentData.oldStartDate,
        appointmentData.oldEndDate,
        "-"
      );
      //ajouter les values pour le nouvel interval
      this.updateIntervalValuesForDateRange(
        appointmentData,
        appointmentData.startDate,
        appointmentData.endDate,
        "+"
      );

      //set old start date
      appointmentData.oldStartDate = appointmentData.startDate;
      appointmentData.oldEndDate = appointmentData.endDate;
    },
    format(date) {
      return date ? this.$dayjs(date).format("L") : "";
    },
    cancelEdit() {
      this.valuesForDates = cloneDeep(this.valuesForDatesCopy);
      this.openEditCapacities = false;
      this.editSectionItems = [];
    },
    async applyEdit() {
      //envoyer les nouvelles dates
      if (this.model.type == "Progress") {
        let config = false;
        if (this.model.item.length == 1) {
          let response = await CalendarApiService.getIsMoveLaterProgress(
            this.model.item[0].stepIdPublic
          );
          config = response.data.data;
        }

        if (config) {
          //ouvrir modal
          this.openMoveProgressEventsModal = true;
        } else {
          //update progress appointments dates
          await this.editProgressEvents(true);
        }
      } else if (this.model.type == "Installation") {
        //update reference appointments
        await this.editInstallerEvents();
      }
    },
    cancelEditEvents() {
      this.openMoveProgressEventsModal = false;
    },
    async editInstallerEvents() {
      let data = [];

      for (let item of this.editSectionItems) {
        let tmp = {
          type: "Installation",
          workOrderIdPublic: item.workOrderIdPublic,
          startDate: this.$dayjs(item.startDate).format("YYYY-MM-DD"),
          endDate: this.$dayjs(item.endDate).format("YYYY-MM-DD"),
          moveBrokenLink: true,
        };

        data.push(tmp);
      }

      this.isLoading = true;
      await CalendarApiService.setReferenceAppointmentsDate(data);
      this.isLoading = false;

      EventBus.$emit("reload-all-installer-charts");

      this.openEditCapacities = false;
      this.editSectionItems = [];

      this.openMoveProgressEventsModal = false;
    },
    async editProgressEvents(answer) {
      if (answer != undefined) {
        let model = {
          moveLaterProgress: answer,
          progressAppointmentsDates: [],
        };

        for (let item of this.editSectionItems) {
          let tmp = {
            progressAppointmentId: item.progressAppointmentId,
            startDate: this.$dayjs(item.startDate).format("YYYY-MM-DD"),
            endDate: this.$dayjs(item.endDate).format("YYYY-MM-DD"),
          };

          model.progressAppointmentsDates.push(tmp);
        }

        this.isLoading = true;
        await CalendarApiService.setProgressAppointmentsDates(model);
        this.isLoading = false;

        //reload
        if (answer == true) {
          EventBus.$emit("reload-all-progress-charts");
        } else {
          this.loadValues();
        }
      }

      this.openEditCapacities = false;
      this.editSectionItems = [];

      this.openMoveProgressEventsModal = false;
    },
    getItemsName() {
      let name = "";

      var index = 0;
      this.model.item.forEach((x) => {
        if (index == this.model.item.length - 1) {
          name += x.name;
        } else {
          name += x.name + " | ";
        }

        index++;
      });

      return name;
    },
    removeCapacityChart() {
      CalendarApiService.removeCapacityChart(this.model.id);
      this.$emit("capacity-chart-removed");
    },
  },
  watch: {},
  computed: {
    userCanEditSelectedItems() {
      let disabled = false;

      let type = this.model.type;

      for (var i = 0; i < this.model.item.length; i++) {
        let canEditItem =
          type == "Progress"
            ? this.userCanEditProgress(this.model.item[i])
            : this.userCanEditInstaller(this.model.item[i]);

        if (canEditItem == false) {
          disabled = true;
          break;
        }
      }

      return disabled;
    },
    myStyles() {
      return {
        flex: "1 1 0",
        position: "relative",
      };
    },
    dataCollectionComputed() {
      let labels = this.getDateLabels();
      let dataSet = this.getDataSet();
      let dataCollection = {
        labels: labels,
        datasets: dataSet,
      };

      return dataCollection;
    },
    orderedEditSectionItems() {
      //ordonner selon le selected value type
      let selectedValueType = this.selectedValueType;

      let sortFn = (a, b) => {
        let aValueForType = this.getValueForType(selectedValueType, a.values);
        let bValueForType = this.getValueForType(selectedValueType, b.values);

        return aValueForType > bValueForType
          ? -1
          : bValueForType > aValueForType
          ? 1
          : 0;
      };

      let items = this.editSectionItems.slice().sort(sortFn);
      if (this.hideCompleted) {
        return items.filter(x => x.completed === false)
      }
      return items
    },
  },
};
</script>

<template>
  <div
    class="mb-2 p-3 card card-body boxShadow"
    :style="{
      minHeight: openEditCapacities == false ? 370 + 'px' : 850 + 'px',
    }"
    style="
      position: relative;
      min-height: 320px;
      min-width: 0;
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
    "
  >
    <loading
      :active.sync="isLoading"
      style="border-radius: 0.7em"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    ></loading>
    <div style="display: flex; min-width: 0; align-items: center">
      <p class="mb-0" style="flex: 1 1 0">{{ getItemsName() }}</p>
      <b-form-checkbox class="mr-3" size="md" switch v-model="hideCompleted">
        {{ $t("general.hideCompletedProgress") }}
      </b-form-checkbox>
      <b-dropdown
        style="margin-left: auto"
        id="cogDropdown"
        dropleft
        variant="primary"
        :text="$t('general.columns')"
      >
        <template #button-content style="padding: 0">
          <i style="font-size: 1.6em" class="bx bx-cog"></i>
        </template>
        <b-dropdown-form v-for="(value, index) in valueTypes" :key="index">
          <loading
            :active.sync="isUpdatingValueTypePreference"
            style="border-radius: 0.7em"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :height="70"
            loader="dots"
            :is-full-page="false"
          ></loading>
          <b-form-checkbox
            class="mr-3"
            size="md"
            v-model="value.selected"
            @change="toggleValueType(value)"
            switch
            ><span style="white-space: nowrap">{{
              $t("general." + value.valueType)
            }}</span></b-form-checkbox
          >
        </b-dropdown-form>

        <b-dropdown-divider></b-dropdown-divider>

        <b-dropdown-item
          v-if="userIsAdmin"
          @click="removeCapacityChart"
          variant="danger"
          >{{ $t("general.delete") }}</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <div
      v-show="openEditCapacities"
      style="display: flex; flex-direction: column"
    >
      <div
        class="mt-2 innerShadow p-2 chromeThinScrollBar"
        style="
          scrollbar-width: thin;
          background: #f8f8f87d;
          border-radius: 0.4em;
          overflow: auto;
          max-height: 370px;
          min-height: 150px;
        "
      >
        <div class="table-responsive mb-0" style="scrollbar-width: thin">
          <table class="table table-hover" style="zflex: 1 1 0">
            <thead v-show="false" style="zdisplay: none" class="thead-light">
              <tr>
                <th>{{ $t("general.name") }}</th>
                <th style="text-align: center"></th>
                <th style="text-align: center; width: 200px"></th>
                <th style="width: 100px; text-align: center; width: 200px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in orderedEditSectionItems" :key="index">
                <td style="vertical-align: middle">{{ item.workOrderName }}</td>
                <td style="text-align: center; vertical-align: middle">
                  {{ $t("general." + selectedValueType) }} :
                  {{ getValueForType(selectedValueType, item.values) }}
                </td>
                <td style="text-align: center">
                  <date-picker
                    :disabled="userCanEditSelectedItems"
                    @change="changeStartDate(item)"
                    v-model="item.startDate"
                    class="mr-2"
                    style="max-width: 250px"
                    :first-day-of-week="1"
                    :formatter="{
                      stringify: (date) => {
                        return format(date);
                      },
                    }"
                    :placeholder="$t('general.startDate')"
                  >
                  </date-picker>
                </td>
                <td>
                  <date-picker
                    :disabled="userCanEditSelectedItems"
                    @change="changeEndDate(item)"
                    v-model="item.endDate"
                    style="max-width: 250px"
                    :first-day-of-week="1"
                    :formatter="{
                      stringify: (date) => {
                        return date ? $dayjs(date).format('L') : '';
                      },
                    }"
                    lang="en"
                    :placeholder="$t('general.endDate')"
                  >
                  </date-picker>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-2" style="margin-left: auto">
        <b-button
          @click="cancelEdit"
          variant="primary"
          size="sm"
          class="mr-2"
          >{{ $t("general.cancel") }}</b-button
        >
        <b-button @click="applyEdit" variant="primary" size="sm">{{
          $t("general.apply")
        }}</b-button>
      </div>
    </div>
    <div
      class="chart-container mt-1"
      style="
        flex-grow: 1 1 0;
        min-height: 0;
        min-width: 0;
        background-color: white;
      "
    >
      <LineChart
        ref="lineChart"
        style="position: relative; min-width: 0; width: 100% !important"
        :chart-data="dataCollectionComputed"
        :options="options"
      >
      </LineChart>
    </div>

    <MoveProgressEventsModal
      v-model="openMoveProgressEventsModal"
      @on-answer="editProgressEvents"
      @on-cancel="cancelEditEvents"
    ></MoveProgressEventsModal>
  </div>
</template>

<style >
.chart-container {
  flex-grow: 1;
  min-height: 0;
}

.chart-container > div {
  position: relative;
  height: 100%;
  width: 100% !important;
}
</style>
