import Vue from 'vue';
export default {
    getMaximumCapacitiesByPeriod(intervalType, capacities, progresses, dates, endDate, blockerEvents) {
        if (intervalType === 'days') {
            return this.getMaximumCapacitiesByPeriodByDay(capacities, progresses, dates, blockerEvents)
        }
        if (intervalType === 'weeks') {
            return this.getMaximumCapacitiesByPeriodByWeek(capacities, progresses, dates, endDate, blockerEvents);
        }
        if (intervalType === 'months') {
            return this.getMaximumCapacitiesByPeriodByMonth(capacities, progresses, dates, endDate, blockerEvents);
        }
        return [];
    },
    getMaximumCapacitiesByPeriodByDay(capacities, progresses, dates, blockerEvents) {
        let data = [];

        let capacity = {};
        capacity["workday"] = 0;
        capacity["saturday"] = 0;
        capacity["sunday"] = 0;
        if (capacities.length > 0) {
            capacities.forEach((x) => {
                capacity["workday"] += x.max;
                let progress = progresses.find((pr) => pr.id == x.progressId);
                if (progress.includeSaturday) {
                    capacity["saturday"] += x.max;
                }
                if (progress.includeSunday) {
                    capacity["sunday"] += x.max;
                }
            });
        }
        for (let date of dates) {
            if (this.dayIsBlocked(date, blockerEvents)) {
                data.push(0);
            } else if (date.getDay() === 6) {
                data.push(capacity["saturday"]);
            } else if (date.getDay() === 0) {
                data.push(capacity["sunday"]);
            } else {
                data.push(capacity["workday"]);
            }
        }

        return data;
    },
    dayIsBlocked(date, blockerEvents) {
        var isBlocked = false;
        var i = 0;
        while (!isBlocked && i < blockerEvents.length) {
            if (Vue.prototype.$dayjs(date).isSameOrAfter(Vue.prototype.$dayjs(blockerEvents[i].startDate)) && Vue.prototype.$dayjs(date).isBefore(Vue.prototype.$dayjs(blockerEvents[i].endDate))) {
                isBlocked = true;
            }
            i++;
        }
        return isBlocked;
    },
    workedDaysForPeriod(start, end, includeSaturday, includeSunday, blockerEvents) {
        var workedDays = 0;
        var currentDay = start;
        var lastDay = end;
        while (currentDay.getTime() <= lastDay.getTime()) {
            var saturdayValid = includeSaturday || currentDay.getDay() != 6;
            var sundayValid = includeSunday || currentDay.getDay() != 0;
            var isNotBlocked = !this.dayIsBlocked(currentDay, blockerEvents);
            if (saturdayValid && sundayValid && isNotBlocked) {
                workedDays++;
            }
            currentDay = new Date(Vue.prototype.$dayjs(currentDay).add(1, "day"));
        }
        return workedDays
    },
    getMaximumCapacitiesByPeriodByWeek(capacities, progresses, dates, endDate, blockerEvents) {
        let data = [];
        for (let date of dates) {
            var currentEndDate = new Date(Math.min(new Date(Vue.prototype.$dayjs(endDate).add(1, "day")), new Date(Vue.prototype.$dayjs(date).add(6, "day"))));
            let capacity = 0;
            capacities.forEach((x) => {
                let progress = progresses.find((pr) => pr.id == x.progressId);
                var numberOfDaysWorkOrked = this.workedDaysForPeriod(date, currentEndDate, progress.includeSaturday, progress.includeSunday, blockerEvents);
                capacity += x.max * numberOfDaysWorkOrked;
            });
            data.push(capacity);
        }

        return data;
    },
    // eslint-disable-next-line no-unused-vars
    getMaximumCapacitiesByPeriodByMonth(capacities, progresses, dates, endDate, blockerEvents) {
        let data = [];
        for (let date of dates) {
            var currentEndDate = new Date(Math.min(new Date(Vue.prototype.$dayjs(endDate).add(1, "day")), new Date(Vue.prototype.$dayjs(date).add(1, "month").add(-1, "day"))));
            let capacity = 0;
            capacities.forEach((x) => {
                let progress = progresses.find((pr) => pr.id == x.progressId);
                var numberOfDaysWorkOrked = this.workedDaysForPeriod(date, currentEndDate, progress.includeSaturday, progress.includeSunday, blockerEvents);
                capacity += x.max * numberOfDaysWorkOrked;
            });
            data.push(capacity);
        }

        return data;
    }
}